import { Feature, Features } from '@arcadehq/shared/types'
import { AuthUser } from 'next-firebase-auth'
import { Account } from 'src/auth/Account'
import { ModelDefaults, Team, UserProfile } from 'src/types'

export const getSubfield = <
  Obj extends Record<string, any>,
  FieldName extends keyof Obj,
  SubfieldName extends keyof Required<NonNullable<Obj[FieldName]>>
>(
  obj: Obj | null,
  fieldName: FieldName,
  subfieldName: SubfieldName
) => {
  const field = obj?.[fieldName]
  if (!field) return undefined

  // Workaround for https://github.com/microsoft/TypeScript/issues/21760
  const subfield = (field as Required<typeof field>)[subfieldName]
  return subfield ?? undefined
}

export const setOrDeleteSubfield = <
  Obj extends Record<string, any>,
  FieldName extends keyof Obj,
  SubfieldName extends keyof Required<NonNullable<Obj[FieldName]>>,
  Value extends Required<NonNullable<Obj[FieldName]>>[SubfieldName] | undefined
>(
  obj: (Obj & ModelDefaults<Obj>) | null,
  fieldName: FieldName,
  subfieldName: SubfieldName,
  newValue: Value,
  userId: string
) => {
  if (!obj) return Promise.resolve(false)

  const updatedField: NonNullable<Obj[FieldName]> = {
    ...((obj[fieldName] ?? {}) as Obj[FieldName]),
    [subfieldName]: newValue,
  }

  if (!newValue) {
    // Workaround for https://github.com/microsoft/TypeScript/issues/21760
    delete (updatedField as Required<typeof updatedField>)[subfieldName]
  }

  return obj.update(
    {
      [fieldName]: updatedField,
    } as Obj[FieldName],
    userId
  )
}

export function isArcadeTeamMember(email: string) {
  return email.endsWith('@arcade.software')
}

export function shouldRedirectPreprodToProdSSR(
  isPreprodEnv: boolean,
  email?: string
) {
  if (!isPreprodEnv) {
    return false
  }

  // If logged out in preprod, redirect to prod
  if (!email) {
    return true
  }

  if (isArcadeTeamMember(email)) {
    return false
  }

  return true
}

export function shouldRedirectPreprodToProd(
  isPreprodEnv: boolean,
  path: string,
  authUser: Pick<AuthUser, 'clientInitialized' | 'email'>
) {
  if (!isPreprodEnv) {
    return false
  }

  // Ignore the auth page to allow Arcade members to sign in!
  if (path === '/auth') {
    return false
  }

  // Allow us to log out if we ever log in with a non-Arcade account!
  if (path === '/logout') {
    return false
  }

  // Not yet initialized, ignore
  if (!authUser.clientInitialized) {
    return false
  }

  if (isArcadeTeamMember(authUser.email ?? '')) {
    return false
  }

  return true
}

export const shouldRedirectToWelcome = (account: Account, url: string) =>
  account.isLoggedIn &&
  account.needsOnboardingForm &&
  !url.startsWith('/auth') &&
  !url.startsWith('/settings') &&
  !url.startsWith('/welcome') &&
  // prevent scenario where user looses their new arcade or is
  // redirected when editing/viewing it
  !url.startsWith('/upload') &&
  (url.startsWith('/flows/team') || !url.startsWith('/flows/')) &&
  !url.startsWith('/overdue')

export function getFeatureValue<T extends Feature>(
  feature: T,
  userProfile: UserProfile,
  team: Team | null
) {
  const userFeatureValue = getSubfield(userProfile, 'features', feature)

  if (userFeatureValue !== undefined) {
    return userFeatureValue as unknown as Features[T]
  }
  return getSubfield(team, 'features', feature) as Features[T] | undefined
}
