import { getCustomValuesFromQuery } from '@arcadehq/shared/helpers'
import { FlowWrapper } from '@arcadehq/shared/types'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import useAccount from 'src/auth/useAccount'
import { Flow, FlowCustomLink } from 'src/types'
import { theme } from 'tailwind.config'

import { CurrentFlowProps } from './types'

// internal hook. do not use in UI components

interface FlowProviderContextOpt {
  customLink?: FlowCustomLink
  isReadonly: boolean
  isLoading?: boolean
}

export function useFlowProviderContext(
  flow: Flow,
  { customLink, isReadonly, isLoading = false }: FlowProviderContextOpt
): CurrentFlowProps {
  const account = useAccount()
  const router = useRouter()

  // Add 'Noto Color Emoji' as fallback for Emojis
  // This will be used during GIF/Video exports
  const fontFamily = useMemo(
    () =>
      `${
        (flow.font || account.flowDefaults.font) ?? theme.extend.fontFamily.sans
      }, "Noto Color Emoji"`,
    [flow.font, account.flowDefaults.font]
  )

  const [activeStepId, setActiveStepId] = useState<string | null>(null)
  /*
   * Selection
   */

  useEffect(() => {
    if (!flow.steps.length || !flow.id) return
    const [firstStep] = flow.steps
    setActiveStepId(firstStep.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow.id])

  const activeStep = useMemo(() => {
    if (!activeStepId) return null
    return flow.steps.find(step => step.id === activeStepId) ?? null
  }, [flow.steps, activeStepId])

  const activeStepIndex = useMemo(() => {
    if (!activeStepId) return -1
    return flow.steps.findIndex(step => step.id === activeStepId)
  }, [flow.steps, activeStepId])

  const customValues = useMemo(
    () => ({
      ...customLink?.variables,
      ...getCustomValuesFromQuery(router.query),
    }),
    [router.query, customLink]
  )

  return {
    flow,
    isReadonly,
    isLoading,
    hasBackground: !!flow.bgImage?.url,
    hasWrapper: flow.flowWrapper !== FlowWrapper.none,
    hasOverlay: flow.showStartOverlay,
    fontFamily,
    customValues,
    customLink,

    //selection
    activeStep,
    activeStepIndex,
    setActiveStep: setActiveStepId,
  }
}
